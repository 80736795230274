<template>
  <v-container fill-height fluid justify-center>
    <div class="d-flex flex-column align-center">
      <div
        class="text-md-h2 text-h4 primary--text font-weight-bold"
      >
      ⊙﹏⊙
      </div>
      <div class="my-3 text-md-h6 grey--text font-weight-regular">
        Sorry, you're not authorized, try login. 
      </div> 
      <v-btn depressed color="primary" class="text-capitalize" link href="/"
        >Login</v-btn
      >
    </div>
  </v-container>
</template>

<script>
export default {
  data: () => ({}),
  created() {
    this.$store.dispatch("logout");
  },
};
</script>
